<template>
  <validation-observer v-slot="{ handleSubmit }" ref="observer">
    <b-form
      @submit.prevent="handleSubmit(change)"
      class="w-100 h-100 p-4 d-flex flex-column justify-content-between"
    >
      <div class="row">
        <div class="col-lg-8 mb-3">
          <label for="razaoSocial" class="form-label campo-obrigatorio"
            >Razão Social</label
          >
          <validation-provider
            name="razaoSocial"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <input
              type="text"
              id="razaoSocial"
              v-model="empresa.razaoSocial"
              class="form-control"
              :class="classes"
              maxlength="60"
            />
            <div
              class="invalid-feedback"
              v-for="error in errors"
              v-bind:key="error"
            >
              <span class="erro">{{ error }}</span>
            </div>
          </validation-provider>
        </div>

        <div class="col-lg-4 mb-3">
          <label for="cnpj" class="form-label campo-obrigatorio">CNPJ</label>
          <validation-provider
            name="cnpj"
            rules="required"
            v-slot="{ classes, errors }"
          >

           <input
            type="tel"
            id="cnpj"
            v-model="empresa.cnpj"
            v-mask="'##.###.###/####-##'"
            class="form-control"
            :class="classes"
            @blur="formatarCNPJ($event.target.value)"
            :readonly="edit"
            />
            
            <div
              class="invalid-feedback"
              v-for="error in errors"
              v-bind:key="error"
            >
              <span class="erro">{{ error }}</span>
            </div>
          </validation-provider>
        </div>
      </div>

      <div class="pt-4 w-100 d-flex justify-content-center">
        <router-link class="btn btn-light" :to="{ name: 'EmpresaPesquisar' }"
          ><i class="fas fa-times"></i> Cancelar</router-link
        >
        <button type="submit" class="btn btn-success">
          <i class="fas fa-check"></i> Salvar
        </button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formatarCNPJ from "../../../util/formatacao/formatar_cnpj.js";

export default {
  props: {
    mountedForm: {
      type: Object,
    },
  },
  data: function () {
    return {
      empresa: {
        cnpj: "",
        razaoSocial: "",
      },
      edit: false,
    };
  },
  methods: {
    formatarCNPJ(value) {
      this.empresa.cnpj = formatarCNPJ(value);
    },
    reset() {
      this.empresa.razaoSocial = null;
      this.empresa.cnpj = null;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    change() {
      this.$emit("change", this.empresa);
    },
  },
  created() {
    if (this.mountedForm) {
      this.empresa.razaoSocial = this.mountedForm.razaoSocial;
      this.empresa.cnpj = this.mountedForm.cnpj;
      this.edit = true;
    }
  },
};
</script>
